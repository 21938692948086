import React, { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import PrivacyPolicy from "./scripts/PrivacyPolicy";
import Btn from "./UI/btn/Btn";
import InputPhone from "./UI/InputPhone/InputPhone";
import InputText from "./UI/inputText/InputText";
import Context from "./scripts/Context";
import {useEffect} from "react";

const OfferInput = () => {
  const context = useContext(Context);
  // const [yearOfIssue, setYearOfIssue] = useState("");
  // const [referer, setReferer] = useState("");
  const [fio, setFio] = useState("");
  const [mark, setMark] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
//   const [referrer, setReferer] = useState('');

//   useEffect(() => {
//     const referrerValue = window.location ? window.location.referrer : "";
//     setReferer(referrerValue);
// }, []);
  
//  console.log(window.location)


  return (
    <section>
      <Container fluid className="offer_input">
        <Container>
          <div className="offer_input_block">
            <h3 className="offer_input_title">
            Индивидуальные условия при покупки нового автомобиля по TRADE IN !
            </h3>
            <p className="offer_input_subtitle">
            Оцени свой автомобиль ОНЛАЙН
            </p>
            <InputText
              placeholder="Как к Вам обращаться?"
              onChange={setFio}
              value={fio}
            />
            <InputText
              placeholder="Марка вашего авто"
              onChange={setMark}
              value={mark}
            />
            <InputText
              placeholder="Модель вашего авто"
              onChange={setModel}
              value={model}
            />
            <InputText
              placeholder="Год выпуска"
              onChange={setYear}
              value={year}
            />
            
            <div style={{ marginLeft: "10px" }}>
              <InputPhone setTelValue={context.setTelValue} />
            </div>
            <Btn 
                style={{margin: '10px', width: '40%'}} 
                click={(e) => context.handleSubmit(e, fio, mark, model, year)}
            > {
                context.isLoading ? (
                    <svg
                        class="loading-spinner"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            opacity="0.2"
                            fillrule="evenodd"
                            cliprule="evenodd"
                            d="M13.5 21.375C14.5342 21.375 15.5582 21.1713 16.5136 20.7756C17.4691 20.3798 18.3372 19.7997 19.0685 19.0685C19.7997 18.3372 20.3798 17.4691 20.7756 16.5136C21.1713 15.5582 21.375 14.5342 21.375 13.5C21.375 12.4658 21.1713 11.4418 20.7756 10.4864C20.3798 9.53093 19.7997 8.6628 19.0685 7.93153C18.3372 7.20027 17.4691 6.6202 16.5136 6.22445C15.5582 5.82869 14.5342 5.625 13.5 5.625C11.4114 5.625 9.40838 6.45469 7.93153 7.93153C6.45469 9.40838 5.625 11.4114 5.625 13.5C5.625 15.5886 6.45469 17.5916 7.93153 19.0685C9.40838 20.5453 11.4114 21.375 13.5 21.375ZM13.5 24.75C19.7134 24.75 24.75 19.7134 24.75 13.5C24.75 7.28663 19.7134 2.25 13.5 2.25C7.28663 2.25 2.25 7.28663 2.25 13.5C2.25 19.7134 7.28663 24.75 13.5 24.75Z"
                            fill="white"
                        />
                        <path
                            d="M2.25 13.5C2.25 7.28663 7.28663 2.25 13.5 2.25V5.625C11.4114 5.625 9.40838 6.45469 7.93153 7.93153C6.45469 9.40838 5.625 11.4114 5.625 13.5H2.25Z"
                            fill="white"
                        />
                    </svg>
                ) : 'Оставить заявку'
            }
            </Btn>
            <PrivacyPolicy style={{ maxWidth: "40%", marginLeft: "10px" }} />
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default OfferInput;
