import React from 'react';

const SuccessModal = ({setIsOpen, isOpen}) => {
    return (
        <div className={`modal_bg ${isOpen ? 'opened' : ''}`}>
            <div className={`modal_bg_container`}>
                <h3>Заявка отправлена!</h3>
                <p>Поздравляем!</p>
                <button onClick={() => setIsOpen(false)}>Закрыть</button>
            </div>
        </div>
    );
};

export default SuccessModal;