import { Map, YMaps } from '@pbe/react-yandex-maps';
import React from 'react';
import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Context from './scripts/Context';
import Btn from './UI/btn/Btn';


const Contacts = () => {
    const valueFromProvider = useContext(Context);

    return (
        <Container fluid className='py-5'>
            <Container>
                <h2 className='contacts_title'>Контакты</h2>
                <div className='d-block d-sm-flex'>
                    <div className='contacts_list'>
                        <div className='contacts_item'>
                            <p>Адрес нашего салона:</p>
                            <p>г. Нижний Новгород, ул.Усольская 69</p>
                        </div>

                        <div className='contacts_item'>
                            <p>Режим работы:</p>
                            <p>с 9:00 до 20:00 без выходных</p>
                        </div>

                        <div className='contacts_item'>
                            <p>Контактный телефон:</p>
                            <p><a href="#tel">+7 (831) 219-96-61</a></p>
                        </div>

                        <div className='contacts_item requisites'>
                            <p>Реквизиты:</p>
                            <p>ООО «АВТО-МОТО-САЛОН»</p>
                            <p>ОГРН: 1176313035359</p>
                            <p>ИНН: 6321429554 </p>
                            <p>Юр. адрес: 445031, Самарская обл., г. Тольятти, ул. 70 лет Октября, д. 60, кв. 5</p>
                        </div>

                        <h3 style={{fontSize: '24px', fontWeight: '700'}}>Остались вопросы?</h3>
                        <p>Оставьте заявку и мы свяжемся с Вами</p>

                        <Btn style={{width: '100%'}} click={() => valueFromProvider.setIsOpen(true)}>Оставить заявку</Btn>
                    </div>

                    <div className='contacts_map'>
                        <YMaps className='map'>
                            <Map defaultState={{ center: [55.75, 37.57], zoom: 9 }} className='map' />
                        </YMaps>
                    </div>
                </div>
            </Container>
        </Container>
    );
};

export default Contacts;