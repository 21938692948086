import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import coolray from './../img/equipment/coolray.png';


import atlas from './../img/equipment/atlaspro.png';


import tugella from './../img/equipment/tugella.png';

import mw from './../img/equipment/monjaro white.png';
import mb from './../img/equipment/monjaro black.png';



import Btn from './UI/btn/Btn';
import { useContext } from 'react';
import Context from './scripts/Context';

const ChooseComplectation = () => {
    const [equipment, setEquipment] = useState([
        {name: 'GEELY COOLRAY', carEquipment: 'Comfort', img: coolray, characteristics: '1.5 Л. (150 Л.С.) 7DCT FWD'},
        {name: 'GEELY COOLRAY', carEquipment: 'Comfort', img: coolray, characteristics: '1.5 (150 Л.С.) 7-РОБОТ FWD'},
        {name: 'GEELY COOLRAY', carEquipment: 'Flagship', img: coolray, characteristics: '1.5 (150 Л.С.) 7-РОБОТ FWD'},
        {name: 'GEELY ATLAS PRO', carEquipment: 'Luxury', img: atlas, characteristics: '1.5 (177 Л.С.) 7DCT 4WD'},
        {name: 'GEELY ATLAS PRO', carEquipment: 'Flagship', img: atlas, characteristics: '1.5 (177 Л.С.) 7DCT 4WD'},
        {name: 'GEELY ATLAS PRO', carEquipment: 'Flagship +', img: atlas, characteristics: '1.5 (177 Л.С.) 7DCT 4WD'},
        {name: 'GEELY TUGELLA', carEquipment: 'Luxury', img: tugella, characteristics: '2.0 (238 Л.С.) 8AT 4WD'},
        {name: 'GEELY TUGELLA', carEquipment: 'Flagship', img: tugella, characteristics: '2.0 (238 Л.С.) 8AT 4WD'},
        {name: 'GEELY MONJARO', carEquipment: 'Luxury', img: mw, characteristics: '2.0 (238 Л.С.) 8AT 4WD'},
        {name: 'GEELY MONJARO', carEquipment: 'Flagship', img: mb, characteristics: '2.0 (238 Л.С.) 8AT 4WD'},
        {name: 'GEELY MONJARO', carEquipment: 'Exclusive', img: mb, characteristics: '2.0 (238 Л.С.) 8AT 4WD'},
        
    ]);

    const [sortedModel, setSortedModel] = useState('Выберите модель');
    const [sortedData, setSortedData] = useState([...equipment]);
    const [sortedEquipment, setSortedEquipment] = useState('Выберите комплектацию');
    const [complectationOptions, setComplectationOptions] = useState([])

    const handleSortChangeModel = (e) => {
        setSortedModel(e.target.value);
        sortDataModel(e.target.value);
    }

    const sortDataModel = (e) => {
        if (e === 'Выберите модель') {
            setSortedData([...equipment])
            setSortedEquipment('Выберите комплектацию')
            setDisabledMode(true)
        } else if (e !== 'Выберите модель') {
            const result = [...equipment.filter(i => i.name === e)]
            setSortedData([...result])
            setSortedEquipment('Выберите комплектацию')

            const arrayCom = []
            result.forEach(i => {
                if (!arrayCom.includes(i.carEquipment)) {
                    arrayCom.push(i.carEquipment)
                }
            })
            setComplectationOptions([...arrayCom])
            setDisabledMode(false)
        }
    }

    const [disabledMode, setDisabledMode] = useState(true);

    const handleSortChangeEquipment = (e) => {
        setSortedEquipment(e.target.value)
        sortDataEquipment(e.target.value)
    }

    const sortDataEquipment = (e) => {
        if (e === 'Выберите комплектацию') {
            setSortedData([...equipment.filter(i => i.name === sortedModel)])
        } else {
            setSortedData([...equipment.filter(i => i.name === sortedModel && i.carEquipment === e)])
        }
    }

    const [show, setShow] = useState(false);

    const valueFromContext = useContext(Context)

    return (
        <Container fluid>
            <Container>
                <section className='choose_equipment'>
                    <h3>Подбери свою идеальную комплектацию</h3>
                    <div className='choose_first_block'>
                        <div className='choose_select_wrapper'>
                            <p>Модель</p>
                            <select name={sortedModel} onChange={handleSortChangeModel} className='choose_selects'>
                                <option value="Выберите модель">Выберите модель</option>
                                <option value="GEELY COOLRAY">GEELY COOLRAY</option>
                                <option value="GEELY ATLAS PRO">GEELY ATLAS PRO</option>
                                <option value="GEELY TUGELLA">GEELY TUGELLA</option>
                                <option value="GEELY MONJARO">GEELY MONJARO</option>
                            </select>
                        </div>

                        <div className='choose_select_wrapper'>
                            <p>Комплектация</p>
                            <select name={sortedEquipment} onChange={handleSortChangeEquipment} disabled={disabledMode} className='choose_selects'>
                                <option value="Выберите комплектацию" selected>Выберите комплектацию</option>
                                {complectationOptions.map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div>
                        <div>
                            { show ? (
                                sortedData.map((item, index) => (
                                    <div key={index} className='choose_car_equipment'>
                                        <img src={item.img} alt={item.img} className='choose_car_img' />
                                        <p className='choose_car_title'>{item.carEquipment}</p>
                                        <p>{item.characteristics}</p>
                                        <div className='choose_btns'>
                                            <Btn click={() => valueFromContext.setIsOpen(true)}>Получить спец. цену</Btn>
                                            <Btn click={() => valueFromContext.setIsOpen(true)}>Рассчитать кредит</Btn>    
                                        </div>
                                    </div>
                                )) 
                            ) : (
                                sortedData.slice(0,2).map((item, index) => (
                                    <div key={index} className='choose_car_equipment'>
                                        <img src={item.img} alt={item.img} className='choose_car_img' />
                                        <p className='choose_car_title'>{item.carEquipment}</p>
                                        <p>{item.characteristics}</p>
                                        <div className='choose_btns'>
                                            <Btn click={() => valueFromContext.setIsOpen(true)}>Получить спец. цену</Btn>
                                            <Btn click={() => valueFromContext.setIsOpen(true)}>Рассчитать кредит</Btn>    
                                        </div>
                                    </div>
                                ))
                            )}

                            { show ? (
                                <p onClick={() => setShow(false)} className='choose_show'>Скрыть</p>
                            ) : (
                                <p onClick={() => setShow(true)} className='choose_show'>Смотреть все</p>
                            )}
                        </div>
                    </div>
                </section>
            </Container>
        </Container>
    );
};

export default ChooseComplectation;