import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import logo from './../img/logo.png';
import tel from './../img/header_tel.svg';
import map from './../img/header_map.svg';
import Btn from './UI/btn/Btn';
import Context from './scripts/Context';

const Header = ({toScroll}) => {
    const valueFromContext = useContext(Context);
    const scrollToTarget = (target) => {
        if (target) {
            target.scrollIntoView({behavior: 'smooth'})
        }
    }

    return (
        <Container fluid>
            <Container className='header_container'>
                <header>
                    <div className='header_wrapper not_mobile'>
                        <div className='header_logo_section'>
                            <img src={logo} alt="logo" />
                            <p className='header_logo_section_title'>Автосалон GEELY</p>
                        </div>

                        <div className='header_adress'>
                            <img src={map} alt="map.svg" />
                            <span className='d-none d-sm-inline'>г. Нижний Новгород, ул.Усольская 69</span>
                            <span className='d-inline d-sm-none'>г. Нижний Новгород</span>
                        </div>

                        <div className='header_phone'>
                            <img src={tel} alt="tel.svg" />
                            <a href="tel:+78312199661">+7 (831) 219-96-61</a>
                        </div>

                        <Btn click={() => valueFromContext.setIsOpen(true)}>Оставить заявку</Btn>
                    </div>
                </header>
                <div className="header_bottom">
                    <div className="header_menu">
                        <nav className="main_menu">
                            <ul className='main_menu_list'>
                                <li className='main_menu_item'>
                                    <a href="#autos" onClick={() => scrollToTarget(toScroll.timer)}>Авто в наличии</a>
                                </li>

                                <li className='main_menu_item'>
                                    <a href="#complects" onClick={() => scrollToTarget(toScroll.chooseCom)}>Комплектации</a>
                                </li>

                                <li className='main_menu_item'>
                                    <a href="#trade_in" onClick={() => scrollToTarget(toScroll.offerInput)}>Трейд ин</a>
                                </li>

                                <li className='main_menu_item'>
                                    <a href="#creditts" onClick={() => scrollToTarget(toScroll.creditSec)}>Автокредит</a>
                                </li>

                                <li className='main_menu_item'>
                                    <a href="#contacts" onClick={() => scrollToTarget(toScroll.contacts)}>Контакты</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </Container>
        </Container>
    );
};

export default Header;