import React, {useState, useEffect, useMemo} from 'react';
import TimerSection from './TimerSection';

const Timer = () => {
    const endDate = useMemo(() => new Date('2023-11-15 23:59').getTime(), []); 

    const difference = endDate - new Date().getTime();

    const [days, setDays] = useState(Math.floor(difference / (1000 * 60 * 60 * 24)));
    const [hours, setHours] = useState(Math.floor((difference / (1000 * 60 * 60)) % 24));
    const [minutes, setMinutes] = useState(Math.floor((difference / (1000 * 60)) % 60));
    const [seconds, setSeconds] = useState(Math.floor((difference / 1000) % 60));

    useEffect(() => {
        const timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeDifference = endDate - currentTime;

        if (timeDifference <= 0) {
            clearInterval(timer);
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);
        } else {
            setDays(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((timeDifference / (1000 * 60 * 60)) % 24));
            setMinutes(Math.floor((timeDifference / (1000 * 60)) % 60));
            setSeconds(Math.floor((timeDifference / 1000) % 60));
        }
        }, 1000);

        return () => {
        clearInterval(timer);
        };
    }, [endDate]); 

    const formatTime = (time) => {
        return time.toString().padStart(2, '0');
    };
    

    return (
        <div>
            <TimerSection 
                days={formatTime(days)} 
                hours={formatTime(hours)} 
                minutes={formatTime(minutes)} 
                seconds={formatTime(seconds)}
            />
        </div>
    );
};

export default Timer;