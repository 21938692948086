import React from 'react';
import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Context from './scripts/Context';
import license from './../img/generalnaya_lizensiya_1000_-08_07_2015.pdf'; 
import insurance from './../img/insurance.pdf'; 

const Footer = () => {
    const context = useContext(Context);
    const handleClick = (e) => {
        e.preventDefault();
        context.handleOpenPrivacyModal(true)
    }

    return (
        <footer>
            <Container fluid className='py-5'>
                <Container>
                    <div className='footer_info'>
                        <p>ООО «АВТО-МОТО-САЛОН» ОГРН: 1176313035359 ИНН: 6321429554 Юридический адрес: 445031, Самарская обл., г. Тольятти, ул. 70 лет Октября, д. 60, кв. 5</p>
                        <p>Стоимость подарка не зависит от стоимости купленного а/м, покупатель может выбрать любой подарок из перечисленных при покупке а/м. Обращаем Ваше внимание на то, что данный сайт носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 Гражданского кодекса Российской Федерации. Для получения более подробной информации об указанных акциях, а также о стоимости автомобилей обращайтесь к менеджерам по продажам.</p>
                        <p>Наш сайт использует файлы cookies для повышения удобства пользователей. Продолжая пользоваться сайтом, вы соглашаетесь с использованием файлов cookies и принимаете нашу</p>
                    </div>
                    <div className='footer_privacy'>
                        <a onClick={handleClick} href=''>Политику конфиденциальности.</a>
                        <a href={license}>Партнер автокредитования: ПАО «Банк ВТБ» Лицензия ЦБ РФ № 1000 от 08.07.2015 г.</a>
                        <a href={insurance}>Партнер автострахования: АО «Тинькофф Страхование» лицензия ОС № 0191-03 от 19.05.2015 г.</a>
                    </div>
                </Container>
            </Container>
        </footer>
    );
};

export default Footer;