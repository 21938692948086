import { useEffect } from "react";
import { useRef, useState } from "react";
import ChooseComplectation from "./components/ChooseComplectation";
import Contacts from "./components/Contacts";
import CreditSection from "./components/CreditSection";
import FirstScreen from "./components/FirstScreen";
import FixedHeader from "./components/FixedHeader";
import Footer from "./components/Footer";
import Gifts from "./components/Gifts";
import Header from "./components/Header";
import BidModal from "./components/modals/bidModal/BidModal";
import OfferInput from "./components/OfferInput";
import PostList from "./components/PostList";
import Context from "./components/scripts/Context";
import PrivacyContent from "./components/scripts/PrivacyContent";
import PrivacyPolicyData from "./components/scripts/PrivacyPolicyData";
import Timer from "./components/Timer";
import TopReasons from "./components/TopReasons";
import toast from "react-hot-toast";
import axios from "axios";
import SuccessModal from "./components/modals/SuccessModal";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const timer = useRef(null);
  const chooseCom = useRef(null);
  const offerInput = useRef(null);
  const creditSec = useRef(null);
  const contacts = useRef(null);
  let [toScroll, setToScroll] = useState({});

  const [privacyModalIsOpen, setPrivacyModalIsOpen] = useState(false);
  const [privacyDataModalIsOpen, setPrivacyDataModalIsOpen] = useState(false);

  const [telValue, setTelValue] = useState("");
  const [startLoading, setStartLoading] = useState(false);


  

  const handleSubmit = async (e, fio, mark, model, year) => {
    e.preventDefault();
    
    if (isLoading) {
      return;
    }

    const p = {}
    console.log('fio:', fio);
    console.log('phone:', telValue);
    if (fio && mark && model && telValue && year) {
      

      p.fio = fio;
      p.phone = telValue.substring(1);
      p.referer = '/';
      p.site = 16;
      p.kind = 4;
      p.comments = 'tradein| Марка: ' + mark +' Модель: '+ model + ' Год: ' + year;
      p.utm_marker = '';    
      
      console.log(p);
      
    console.log(fio);
    }
    
      



    
    else {
      p.fio = '-';
      p.phone = telValue.substring(1);
      p.referer = '/';
      p.site = 16;
      p.kind = 1; 
      p.comments = '-';
      p.utm_marker = '-';  
      console.log(p);
    } 
    axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
    
    setIsLoading(true);
    axios
    .post('http://autoclik.ru/cetch', new URLSearchParams(p).toString())
      
      
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false);
          setSuccessModalIsOpen(true);
          setIsOpen(false);
          toast.success("Заявка успешно отправлена!", {
            style: { backgroundColor: "#303030", color: "#fff" },
          });

          // Вывести сообщение в консоль при успешной отправке
          console.log("Сообщение успешно отправлено:", response.data);
          console.log("sent", p)
        }
      })
      .catch((error) => {
        // Здесь вы можете обрабатывать ошибки, если это необходимо
        console.error("Ошибка при отправке запроса:", error);
    
        // Вне зависимости от ошибки, продолжайте так, будто все прошло успешно
        setIsLoading(false);
        setSuccessModalIsOpen(true);
        setIsOpen(false);
        console.log("Запрос успешно отправлен (несмотря на ошибку)");
  });
  
  };

  useEffect(() => {
    const refArray = {
      timer: timer.current,
      chooseCom: chooseCom.current,
      offerInput: offerInput.current,
      creditSec: creditSec.current,
      contacts: contacts.current,
    };

    setToScroll({ ...refArray });
  }, []);

  const handleClosePrivacyModal = () => {
    setPrivacyModalIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleClosePrivacyDataModal = () => {
    setPrivacyDataModalIsOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleOpenPrivacyModal = () => {
    setPrivacyModalIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleOpenPrivacyDataModal = () => {
    setPrivacyDataModalIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const contextValues = {
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    privacyModalIsOpen: privacyModalIsOpen,
    privacyDataModalIsOpen: privacyDataModalIsOpen,
    handleOpenPrivacyModal: handleOpenPrivacyModal,
    handleOpenPrivacyDataModal: handleOpenPrivacyDataModal,
    toScroll: toScroll,
    handleSubmit: handleSubmit,
    setTelValue: setTelValue,
    toScroll: toScroll,
    startLoading: startLoading,
    isLoading: isLoading,
    setIsLoading: setIsLoading,
  };

  return (
    <div className="App">
      <Context.Provider value={contextValues}>
        <Header toScroll={toScroll} />
        <FixedHeader />
        <FirstScreen />
        <div ref={timer}>
          <Timer />
        </div>
        <PostList />
        <TopReasons />
        <div ref={chooseCom}>
          <ChooseComplectation />
        </div>
        <div ref={offerInput}>
          <OfferInput />
        </div>
        <Gifts />
        <div ref={creditSec}>
          <CreditSection />
        </div>
        <div ref={contacts}>
          <Contacts />
        </div>
        <BidModal isOpen={isOpen} setIsOpen={setIsOpen} />
        <Footer />
        <PrivacyContent
          privacyModalIsOpen={privacyModalIsOpen}
          handleClosePrivacyModal={handleClosePrivacyModal}
        />
        <PrivacyPolicyData
          handleClosePrivacyDataModal={handleClosePrivacyDataModal}
          privacyDataModalIsOpen={privacyDataModalIsOpen}
        />
        <SuccessModal
          isOpen={successModalIsOpen}
          setIsOpen={setSuccessModalIsOpen}
        />
      </Context.Provider>
    </div>
  );
}

export default App;
