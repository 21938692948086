import React from "react";
import { useContext } from "react";
import Context from "../../scripts/Context";
import cl from "./btn.module.css";

const Btn = ({ children, white, style, click, gray }) => {
  const context = useContext(Context);

  return (
    <>
      {white ? (
        <button className={cl.white_btn} style={style} onClick={click}>
          {children}
        </button>
      ) : gray ? (
        <button className={cl.gray_btn} style={style} onClick={click}>
          {children}
        </button>
      ) : (
        <button
          className={cl.btn}
          style={style}
          onClick={click}
          disabled={context.startLoading}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Btn;
