import React, {useContext} from 'react';
import logo from './../img/logo.png';
import tel from './../img/header_tel.svg';
import map from './../img/header_map.svg';
import Btn from './UI/btn/Btn';
import { Container } from 'react-bootstrap';
import Context from './scripts/Context';
import { useState } from 'react';
import BurgerMenu from './BurgerMenu';


const FixedHeader = () => {
    const valueFromContext = useContext(Context);
    const [openBurger, setOpenBurger] = useState(false);

    return (
        <div className='header_full_container'>
            <Container>
                <header>
                    <div className='header_wrapper'>
                        <div className='header_logo_section'>
                            <img src={logo} alt="logo" />
                            <p className='header_logo_section_title'>Автосалон GEELY</p>
                        </div>

                        <div className='header_adress'>
                            <img src={map} alt="map.svg" />
                            <span className='d-none d-lg-inline'>г. Нижний Новгород, ул.Усольская 69</span>
                            <span className='d-inline d-lg-none'>г. Нижний Новгород</span>
                        </div>

                        <div className='header_phone'>
                            <img src={tel} alt="tel.svg" />
                            <a href="tel:+78312199661">+7 (831) 219-96-61</a>
                        </div>

                        <div className='header_burger' onClick={() => setOpenBurger(true)}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>

                        <div className='header_bid_btn'>
                            <Btn click={() => valueFromContext.setIsOpen(true)}>Оставить заявку</Btn>
                        </div>
                    </div>
                </header>
            </Container>
            <BurgerMenu openBurger={openBurger} setOpenBurger={setOpenBurger} />
        </div>
    );
};

export default FixedHeader;