import React from 'react';
import { Container } from 'react-bootstrap';
import persentage from './../img/icon_percentage.png';
import podarok from './../img/icon_podarok.png';
import credit from './../img/icon_credit.png';
import pay from './../img/icon_pay.png';
import mobilePhoto from './../img/first_screen_bg.png';
import Btn from './UI/btn/Btn';
import { useContext } from 'react';
import Context from './scripts/Context';

const FirstScreen = () => {
    const valueFromProvider = useContext(Context);
    return (
        <main>
            <div className='first_screen'>
                <div className='first_screen_top d-none d-sm-block'>
                    <div className='first_screen_heading'>
                        <span>Внимание: Финальные дни продаж новых GEELY со скидкой до 40%!</span>
                        <br />
                        <span>ТОЛЬКО до 15 ноября 2023!</span>
                    </div>

                    <div className='d-none d-sm-flex'>
                        <div className="first_screen_advantage_item">
                            <img src={persentage} alt="persentage" />
                            <span>Рассрочка 0%</span>
                        </div>
                        <div className="first_screen_advantage_item">
                            <img src={podarok} alt="podarok" />
                            <span>Зимняя резина в подарок</span>
                        </div>
                        <div className="first_screen_advantage_item">
                            <img src={credit} alt="credit" />
                            <span>Льготный кредит от 4,9%</span>
                        </div>
                        <div className="first_screen_advantage_item">
                            <img src={pay} alt="pay" />
                            <span>3 платeжа по кредиту в подарок</span>
                        </div>
                    </div>
                </div>
                
                <Container className='d-none d-sm-block'>
                    <div className='first_screen_bigbtn'>
                        <Btn click={() => valueFromProvider.setIsOpen(true)} style={{textTransform: 'uppercase', padding: '1rem 3rem'}}>Оставить заявку</Btn>
                    </div>
                </Container>

                
            </div>

            <div className='w-100 d-block d-sm-none first_screen_mobile_title'>
                <span>Внимание: Финальные дни продаж новых HAVAL со скидкой до 40%!</span>
                <br />
                <span>Акция до 15 ноября!</span>
            </div>

            <img src={mobilePhoto} alt="mobilePhoto" className='d-block d-sm-none mobile_photo' />

            <div className='d-flex d-sm-none first_screen_mobile_advantages'>
                <div>
                    <img src={persentage} alt="persentage" />
                    <span>Рассрочка 0%</span>
                </div>
                <div>
                    <img src={podarok} alt="podarok" />
                    <span>Зимняя резина в подарок</span>
                </div>
                <div>
                    <img src={credit} alt="credit" />
                    <span>Льготный кредит от 9%</span>
                </div>
                <div>
                    <img src={pay} alt="pay" />
                    <span>3 платeжа по кредиту в подарок</span>
                </div>
            </div>
        </main>
    );
};

export default FirstScreen;