import React, {useContext, useState} from 'react';
import { Container } from 'react-bootstrap';
import Slider from './scripts/slider/Slider';
import Btn from './UI/btn/Btn';


import jolion1 from './../img/img1_1.jpg';
import jolion2 from './../img/img1_2.jpg';
import jolion3 from './../img/img1_3.jpg';
import jolion4 from './../img/img1_4.jpg';
import jolion5 from './../img/img1_5.jpg';


import dargo1 from './../img/img2_1.jpg';
import dargo2 from './../img/img2_2.jpg';
import dargo3 from './../img/img2_3.jpg';
import dargo4 from './../img/img2_4.jpg';
import dargo5 from './../img/img2_5.jpg';


import h91 from './../img/img3_1.jpg';
import h92 from './../img/img3_2.jpg';
import h93 from './../img/img3_3.jpg';
import h94 from './../img/img3_4.jpg';
import h95 from './../img/img3_5.jpg';
import h96 from './../img/img3_6.jpg';

import f71 from './../img/img4_1.jpg';
import f72 from './../img/img4_2.jpg';
import f73 from './../img/img4_3.jpg';
import f74 from './../img/img4_4.jpg';
import f75 from './../img/img4_5.jpg';




//icons
import credit from './../img/icon_credit_black.png';
import podarok from './../img/icon_podarok_black.png';
import percentage from './../img/icon_percentage_black.png';
import pay from './../img/icon_pay_black.png';
import Context from './scripts/Context';


const PostList = () => {
    const valueFromContext = useContext(Context);

    const scrollToTarget = (target) => {
        if (target) {
            target.scrollIntoView({behavior: 'smooth'})
        }
    }
    
    const post = [
        {carName: 'GEELY COOLRAY', priceStrike: '2 144 990₽', priceFrom: '914 000', benefit: '1 230 990', credit: '14 750', imgs: [
            jolion1, jolion2, jolion3, jolion4, jolion5
        ]},
        {carName: 'GEELY ATLAS PRO', priceStrike: '2 388 990₽', priceFrom: '1 448 000', benefit: '940 990', credit: '23 296', imgs: [
            dargo1, dargo2, dargo3, dargo4, dargo5
        ]},
        {carName: 'GEELY TUGELLA', priceStrike: '3 734 990₽', priceFrom: '2 536 000', benefit: '1 198 990', credit: '43 327', imgs: [
            h91, h92, h93, h94, h95, h96
        ]},
        {carName: 'GEELY MONJARO', priceStrike: '4 524 990₽', priceFrom: '2 975 000', benefit: '1 549 990', credit: '47 865', imgs: [
            f71, f72, f73, f74, f75
        ]},
        
    ]

    return (
        <Container fluid className='post_list'>
            <Container>
                {post.map((item, index) => (
                    <div key={index}>
                        <h2 className='pt-5 post_title'>{item.carName}</h2>
                        <div className='d-block d-md-flex w-100 bg-white p-3'>
                            <div className='all_swiper_wrapper'>
                                <Slider imgs={item.imgs} />
                                
                            </div>
                            <div className='post_desc'>
                                <div className='post_advantages'>
                                    <div className='post_advantages_item'>
                                        <img src={percentage} alt={percentage} />
                                        <span>Рассрочка 0%</span>
                                    </div>

                                    <div className='post_advantages_item'>
                                        <img src={podarok} alt={podarok} />
                                        <span>Льготный кредит от 4,9%</span>
                                    </div>

                                    <div className='post_advantages_item'>
                                        <img src={credit} alt={credit} />
                                        <span>Зимняя резина в подарок</span>
                                    </div>

                                    <div className='post_advantages_item'>
                                        <img src={pay} alt={pay} />
                                        <span>3 платежа по кредиту в подарок</span>
                                    </div>
                                </div>

                                <div className='post_price_desc'>
                                <p>Цена <strike>{item.priceStrike}</strike>   <span style={{ color: 'red' }}>{item.priceFrom} ₽</span> </p>
                                    <p>Выгода до {item.benefit} ₽ !</p>
                                    <p>Кредит от {item.credit} ₽/мес</p>

                                    <Btn click={() => valueFromContext.setIsOpen(true)}>Получить специальные условия</Btn>
                                    <Btn white={true} click={() => valueFromContext.setIsOpen(true)}>Рассчитать кредит</Btn>
                                    <Btn gray={true} click={() => scrollToTarget(valueFromContext.toScroll.chooseCom)}>Подобрать комплектацию</Btn>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Container>
        </Container>
    );
};

export default PostList;